import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPropertiesThunk } from "../store/thunks/repliersThunk";
import Map from "../components/maplibre/Map";
import PropertyCarousel from "../components/properties/PropertyCarousel";

import { Switch } from "@headlessui/react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import * as Slider from "@radix-ui/react-slider";

export default function DiscoverPage() {
  const dispatch = useDispatch();
  const [listings, setListings] = useState([]);

  const [priceRange, setPriceRange] = useState([0, 2000000]);
  const [bedrooms, setBedrooms] = useState(1);
  const [propertyType, setPropertyType] = useState("House");
  const [listingType, setListingType] = useState("Sale");
  const [squareFootage, setSquareFootage] = useState(0);
  const [showSold, setShowSold] = useState(false);

  const mapRef = useRef(null);
  const [userLocation, setUserLocation] = useState(null);
  const [areFiltersVisible, setAreFiltersVisible] = useState(true);

  // Default location for the map
  const [propertyCoordinates, setPropertyCoordinates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(fetchPropertiesThunk()).unwrap();
      setListings(result.listings || []);
    };
    fetchData();
  }, [dispatch]);

  console.log("listings", listings);

  // Get user location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          console.log({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, []);

  // Extract coordinates from propertyData
  useEffect(() => {
    if (listings && Array.isArray(listings)) {
      const coordinates = listings.map((property) => ({
        address: property.address,
        longitude: property.map?.longitude,
        latitude: property.map?.latitude,
      }));
      console.log("Extracted coordinates:", coordinates);
      setPropertyCoordinates(coordinates);
    }
  }, [listings]);

  return (
    <div className="flex flex-col md:flex-row bg-gray-50">
      {/* Sidebar (Filters) */}
      <div className=" block relative z-40 bg-white shadow-md border-l border-r border-b border-[#0A2342] p-4 pt-5 w-full md:w-[15%] lg:w-[15%] h-auto md:h-full">
        <div className="flex flex-col space-y-4">
          <h1 className="text-2xl font-semibold text-[#0A2342] my-2 md:ml-0 ">
            Discover Your Dream Home
          </h1>
          <button
            className="bg-gray-100 hover:bg-gray-200 border border-gray-300 text-gray-800 font-semibold py-2 px-4"
            onClick={() => setAreFiltersVisible((prev) => !prev)}>
            {areFiltersVisible ? "Hide Filters" : "Show Filters"}
          </button>
          <button
            className="w-full bg-gray-100 hover:bg-gray-200 border border-gray-300 text-gray-800 font-semibold py-2 px-4 flex items-center justify-center"
            onClick={() => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const { latitude, longitude } = position.coords;
                    if (mapRef.current) {
                      mapRef.current.flyTo({
                        center: [longitude, latitude],
                        zoom: 15,
                        speed: 1,
                        curve: 1,
                        essential: true,
                      });
                    }
                  },
                  (error) => {
                    console.error("Error getting location:", error);
                  }
                );
              } else {
                alert("Geolocation is not supported by this browser.");
              }
            }}>
            <MapPinIcon className="mr-2 h-4 w-4" /> Current Location
          </button>
        </div>

        {areFiltersVisible && (
          <div className="flex flex-col space-y-4 mt-4">
            {/* Price Range */}
            <div className="flex flex-col">
              <label
                htmlFor="price-range"
                className="text-sm font-medium text-gray-700 pb-1">
                Price Range
              </label>
              <Slider.Root
                className="relative flex items-center select-none touch-none w-full h-5"
                value={priceRange}
                onValueChange={setPriceRange}
                min={0}
                max={2000000}
                step={50000}>
                <Slider.Track className="bg-gray-300 relative grow h-[3px]">
                  <Slider.Range className="absolute bg-[#0A2342] h-full" />
                </Slider.Track>
                <Slider.Thumb className="block w-5 h-5 bg-white border-2 border-gray-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
                <Slider.Thumb className="block w-5 h-5 bg-white border-2 border-gray-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
              </Slider.Root>
              <div className="text-sm text-gray-500 mt-1">
                ${priceRange[0].toLocaleString()} - $
                {priceRange[1].toLocaleString()}
              </div>
            </div>

            {/* Bedrooms */}
            <div className="flex flex-col">
              <label
                htmlFor="bedrooms"
                className="text-sm font-medium text-gray-700 pb-1">
                Bedrooms
              </label>
              <input
                id="bedrooms"
                type="number"
                value={bedrooms}
                onChange={(e) => setBedrooms(parseInt(e.target.value))}
                className="border border-gray-300 p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#E5B13A]"
              />
            </div>

            {/* Property Type */}
            <div className="flex flex-col">
              <label
                htmlFor="property-type"
                className="text-sm font-medium text-gray-700 pb-1">
                Property Type
              </label>
              <select
                id="property-type"
                value={propertyType}
                onChange={(e) => setPropertyType(e.target.value)}
                className="border border-gray-300 p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#E5B13A]">
                <option value="House">House</option>
                <option value="Condo">Condo</option>
                <option value="Apartment">Apartment</option>
                <option value="Loft">Loft</option>
              </select>
            </div>

            {/* Listing Type */}
            <div className="flex flex-col">
              <label
                htmlFor="listing-type"
                className="text-sm font-medium text-gray-700 pb-1">
                Listing Type
              </label>
              <select
                id="listing-type"
                value={listingType}
                onChange={(e) => setListingType(e.target.value)}
                className="border border-gray-300 p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#E5B13A]">
                <option value="Sale">For Sale</option>
                <option value="Rent">For Rent</option>
                <option value="Resale">Resale</option>
                <option value="Consignment">Consignment</option>
              </select>
            </div>

            {/* Square Footage */}
            <div className="flex flex-col">
              <label
                htmlFor="square-footage"
                className="text-sm font-medium text-gray-700 pb-1">
                Square Footage
              </label>
              <input
                id="square-footage"
                type="number"
                value={squareFootage}
                onChange={(e) => setSquareFootage(parseInt(e.target.value))}
                className="border border-gray-300 p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#E5B13A]"
              />
            </div>

            {/* Show Sold Toggle */}
            <div className="flex items-center space-x-2">
              <Switch
                id="show-sold"
                checked={showSold}
                onChange={setShowSold}
                className={`${
                  showSold ? "bg-[#0A2342]" : "bg-gray-200"
                } relative inline-flex items-center h-6 w-11`}>
                <span className="sr-only">Show Sold</span>
                <span
                  className={`${
                    showSold ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform bg-white transition`}
                />
              </Switch>
              <label
                htmlFor="show-sold"
                className="text-sm font-medium text-gray-700">
                Show Sold
              </label>
            </div>
          </div>
        )}
      </div>

      {/* Map Section */}
      <div className="flex-grow h-full transition-all">
        <Map
        properties={listings}
          locations={propertyCoordinates.map(
            ({ address, longitude, latitude }) => ({
              address,
              longitude,
              latitude,
            })
          )}
        />

        {/* Property Carousel */}
        <div className="container mx-auto">
          <PropertyCarousel
            title="Shown Properties"
            properties={listings}
          />
        </div>
      </div>
    </div>
  );
}
